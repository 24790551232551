import { ActionTypes } from "../contants/action-types"


// reducers/newsReducer.js
const initialState = {
  "newsList":{
     "news":[{
      "id": "60",
      "heading": "फर्रुखाबाद के थाना कंपिल कायमगंज के ",
      "content": "फर्रुखाबाद के थाना कंपिल कायमगंज के ग्राम बिल्सड़ी निवासी विवेक यादव ने जानकारी दी है कि उनकी पत्नी रूबी यादव 4 सितंबर 2019 से लापता है वह ट्रेन से अपने घर बरेली जा रही थीं। जो कि मानसिक रोगी है। उनकी उम्र 26 वर्ष है। लापता होते समय लाल रंग की नीले किनारी की साड़ी बहने हुई थी। उनका रंग गेंहुआ, चेहरा लंबा, कद करीब 5 फुट 5 इंच है। रूबी यादव के पति ने सूचना देने वाले को ₹25000 का नगद इनाम देने की घोषणा की है और अपने नंबर बताएं हैं इनमें 80 7731 2138, 7897 9356 65 और 95 2068 9694 शामिल हैं।",
      "author": "ADMIN",
      "image_url": "1569683303train.jpg",
      "postdate": "2019-09-28 15:10:03",
      "area": ""
      },]
  }
};

  const newsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_NEWS_LIST:
        return {
          ...state,
          newsList: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default newsReducer;


  
  
  