import { ActionTypes } from "../contants/action-types"

 const setNewsList=(newsList)=>{
    return {
            type:ActionTypes.SET_NEWS_LIST,
            payload: newsList, 
    };
}
export default setNewsList;

