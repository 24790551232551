import { ActionTypes } from "../contants/action-types"

const initialState = {
  "categoryList":{
         "categories":[
          { "id": 1,  
            "name": "BAND-BAJA",
          },
          {
            "id": 2, 
            "name": "BARBER"
          },
          {"id": 3, 
            "name": "BIKE"
          },
          { "id": 4, 
            "name": "BUILDING-MATERIAL"
          },
          { "id": 5,  
            "name": "BUS"
          },
          {"id": 6, 
            "name": "BUY-SELL"
          },
          { "id": 7, 
            "name": "CAB"
          },
          { "id": 8, 
            "name": "CARPENTER"
          },
          {"id": 9, 
            "name": "CATERING"
          },
          {"id": 10, 
            "name": "DELIVERY-BOY"
          },
          {"id": 11, 
            "name": "DOCTOR"
          },
          {"id": 12, 
            "name": "DRIVER"
          },
          {"id": 13, 
            "name": "E-Rickshaw"
          },
          {"id": 14, 
            "name": "ELECTRICIAN"
          },
          {"id": 15, 
            "name": "EMERGENCY"
          },
          {"id": 16, 
            "name": "GARDNER"
          },
          {"id": 17, 
            "name": "GAS"
          },
          {"id": 18, 
            "name": "LABOUR"
          },
          {"id": 19, 
            "name": "MANTAINENCE"
          },
          {"id": 20, 
            "name": "MEDICAL"
          },
          {"id": 21, 
            "name": "MOTO-MECH"
          },
          {"id": 22, 
            "name": "MUSIC-DJ"
          },
       
          {"id": 23, 
            "name": "PAINTER"
          },
          {"id": 24, 
            "name": "PANDIT-JI"
          },
          {"id": 25, 
            "name": "PLUMBER"
          },
          {"id": 26, 
            "name": "POTTER"
          },
          {"id": 27, 
            "name": "RAJ-MISTRI"
          },
          {"id": 28, 
            "name": "RESTAURANTS"
          },
          {"id": 29, 
            "name": "SAFAI"
          },
          {"id": 30, 
            "name": "TEACHER"
          },
          {"id": 31, 
            "name": "TENT-HOUSE"
          },
          {"id": 32, 
            "name": "TRANSPORT"
          },
          {"id": 33, 
            "name": "WATER"
          },
          {"id": 34, 
          "name": "BAI"
        },
        {"id": 35, 
        "name": "KAWADA-WALA"
      },
        ]
  }
};

  const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_CATEGORY_LIST:
        return {
          ...state,
          categoryList: action.payload,
        };
      default:
        return state;
    }
  };
  export default categoryReducer;


  
  
  