// Login.js
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Link } from '@mui/material';
import axios from 'axios';
import { LOGIN_API_ENDPOINT } from '../common/constants/URL';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegisterLinkClick = () => {
    navigate('/signup');
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(LOGIN_API_ENDPOINT, {
        email,
        password,
      });

      if (response.data.success) {
        // Call the login function from the AuthContext to set user data
        login(response.data);
        // Redirect or perform any other actions after successful login
        console.log('Login successful!');
        navigate('/');
      }
    } catch (error) {
      console.error('Login failed', error.message);
      // Handle login failure (e.g., show error message)
    }
  };

  return (
    <div>
      <Container maxWidth="xs">
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>
        <TextField
          label="User ID"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
          Login
        </Button>
      </Container>
      <div>
        <Typography>
          Not registered?{' '}
          <Link component="button" onClick={handleRegisterLinkClick}>
            Register here
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default Login;
