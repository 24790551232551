///import { Box} from '@mui/material';
import React from 'react';
import CardList  from './CardList'


export const Rightbar = () => {
  return (
    //<Box border={1} borderColor={"#959595"} borderRadius={2} flex={4} >
     <CardList/>
    //</Box>
  )
}
