import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NEWS_PHOTO_BASE_PATH } from '../common/constants/URL';

const DetailedNews = (props) => {
  console.log("props",props);
  const { data } = props;

  const photo_url=NEWS_PHOTO_BASE_PATH+data.image_url;

  console.log("data",data);

  return (
    <Card sx={{ maxWidth:800  }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: '#1760a5' }} aria-label="recipe">
            A
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={data.heading}
        subheader={data.postdate}
      />
      <CardMedia
        component="img"
        height="400"
        image={photo_url}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
        {data.content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default DetailedNews;