import React,{useEffect}  from 'react';
import CustomCard from '../common/CustomCard'; // Assuming you've created the CustomCard component
import {  useDispatch, useSelector } from 'react-redux';
import setNewsList from '../../redux/actions/newsAction';
import axios from 'axios';
import { READ_NEWS } from '../common/constants/URL';

const CardList = () => {
  
  const dispatch=useDispatch();

  const fetchDataList=async ()=>{
    
        const response=await axios.get(`${READ_NEWS}?page=0&pageSize=10`)
        .catch((err)=>{console.log("error >>>>",err);})
    
        dispatch(setNewsList(response.data));
        
   }

  useEffect(()=>{
    fetchDataList();
   },[]);


const dataList =useSelector((state)=>state.allNews.newsList);


 

  return (
  
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        {dataList.news.slice(0, 4).map((news) => (
          <CustomCard
          key={news.id} data={news}
          />
        ))}
      </div>
  );
};

export default CardList;
