import { ActionTypes } from "../contants/action-types"

 const setBannerList=(bannerList)=>{
    return {
            type:ActionTypes.SET_BANNER_LIST,
            payload: bannerList, 
    };
}
export default setBannerList;

