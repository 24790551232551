// DetailsView.js
import React from 'react';
import Typography from '@mui/material/Typography';
import AddNews from '../AddNews';
import AddExpert from '../AddExpert';
import AddBanner from '../AddBanner';
import AddVideo from '../AddVideo';
import AddProduct from '../AddProduct';


const DetailsView = ({ selectedTitle }) => {
  if (!selectedTitle) {
    return <Typography variant="h4">No title selected</Typography>;
  }

  switch (selectedTitle.id) {
    case 1:
      return <AddNews />;
    case 2:
      return <AddExpert />;
    case 3:
      return <AddBanner />;
    case 4:
      return <AddVideo />;
    case 5:
      return <AddProduct />;
    default:
      return <Typography variant="h4">Invalid title selected</Typography>;
  }
};

export default DetailsView;
