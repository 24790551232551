// Dashboard.js
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TitleList from './TitleList';
import DetailsView from './DetailsView';
import { useAuth } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { user } = useAuth();
  const navigate =useNavigate();
  
  useEffect(() => {
    // Redirect to home if user is not logged in or is not an admin
    if (!user || user.role !== 'ADMIN') {
      navigate('/');
    }
  }, [user, navigate]);

  const titles = [
    { id: 1, name: 'Add News', details: 'Details for Title 1' },
    { id: 2, name: 'Add Expert', details: 'Details for Title 2' },
    { id: 3, name: 'Add Banner', details: 'Details for Title 3' },
    { id: 4, name: 'Add Video', details: 'Details for Title 4' },
    { id: 5, name: 'Add Products', details: 'Details for Title 5' },
  ];

  const handleTitleClick = (title) => {
    setSelectedTitle(title);
  };
  



  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <TitleList titles={titles} onTitleClick={handleTitleClick} />
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <DetailsView selectedTitle={selectedTitle} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
