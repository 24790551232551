import { Paper } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { useDispatch, useSelector } from 'react-redux';
import setBannerList from '../../redux/actions/bannerAction';
import { BASE_ADDVR_READ, BASE_BANNER_PHOTO } from '../common/constants/URL';


const CarouselComponent = () => {

  const dispatch=useDispatch();
  

  const fetchDataList=async()=>{
        const response=await axios.get(`${BASE_ADDVR_READ}`)
        .catch((err)=>{console.log("error >>>>",err);})
        dispatch(setBannerList(response.data));
        
   }
  useEffect(()=>{
    fetchDataList();
   },[]);


   const dataList =useSelector((state)=>state.allBanner.bannerList);

   const img_url= BASE_BANNER_PHOTO;



  return (
    <Carousel>
      {dataList.addver.map((item, index) => (
          <Paper key={index} style={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img
            src={img_url+item.photo}
            alt={`${item.name}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Paper>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
