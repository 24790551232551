import { ActionTypes } from "../contants/action-types"

const initialState = {
  "expertList":{
    "experts": [
    {
    "id": "23",
    "name": "connect to add ",
    "field": "",
    "address": " ",
    "phone1": "9756052045",
    "phone2": "",
    "note": "its lonely here ",
    "rate": "",
    "photo": "commingsoon.jpeg"
    },]
  }
};

  const expertReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_EXPERT_LIST:
        return {
          ...state,
          expertList: action.payload,
        };
      default:
        return state;
    }
  };
  export default expertReducer;


  
  
  