import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, Input, InputLabel } from '@mui/material';
import { BASE_PRODUCT_ADD_API } from '../common/constants/URL';
import PhotoUpload from '../product/PhotoUpload';

const AddProduct = () => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formData, setFormData] = useState({
    urls: [],
    name: '',
    details: '',
    price: '',
    discounted_price: '',
    discount_percentage: '',
    seller: '',
    phone: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDiscountedPriceChange = (e) => {
    const discountedPrice = e.target.value;
    const price = formData.price;

    // Calculate the discount percentage
    const discountPercentage = price && discountedPrice
      ? (((price - discountedPrice) / price) * 100).toFixed(2)
      : '';

    setFormData({
      ...formData,
      discounted_price: discountedPrice,
      discount_percentage: discountPercentage
    });
  };

  const handleImagesUpload = (files) => {
    const updatedImages = [...uploadedImages, ...files];
    setUploadedImages(updatedImages);
    setFormData(prevFormData => ({
      ...prevFormData,
      urls: updatedImages
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_PRODUCT_ADD_API,
        {
          ...formData,
          urls: uploadedImages,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Post API response:', response);
      setSuccess(true);

      setFormData({
        urls: [],
        name: '',
        details:'',
        price: '',
        discounted_price: '',
        discount_percentage: '',
        seller: '',
        phone:''
      });

      setUploadedImages([]);
    } catch (error) {
      console.error('Error posting data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={formContainerStyles}>
      <form onSubmit={handleSubmit}>
        <TextField
          label="What You want to sell"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />
           <TextField
          label="sell Product Description"
          id="details"
          name="details"
          value={formData.details}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          multiline
          rows={4}
        />

        <TextField
          label=" Origin Price of Product"
          id="price"
          name="price"
          value={formData.price}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />

        <TextField
          label="Discounted Price(price seller is selling) "
          id="discounted_price"
          name="discounted_price"
          value={formData.discounted_price}
          onChange={handleDiscountedPriceChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />

        <Typography variant="h6">Discount Percentage: {formData.discount_percentage} %</Typography>

        <TextField
          label="Name of seller"
          id="seller"
          name="seller"
          value={formData.seller}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />
          <TextField
          label="Phone of seller "
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />

        <PhotoUpload onImagesUpload={handleImagesUpload} />

        {loading ? (
          <Typography variant="body1">Uploading...</Typography>
        ) : success ? (
          <div>
            <Typography variant="body1">Product added successfully!</Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => setSuccess(false)}
            >
              Create Another
            </Button>
          </div>
        ) : (
          <Button type="submit" variant="contained" color="primary" sx={submitButtonStyles}>
            Submit
          </Button>
        )}

      </form>
    </Box>
  );
};

const formContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  margin: 'auto'
};

const submitButtonStyles = {
  marginTop: '10px'
};

export default AddProduct;
