import { ActionTypes } from "../contants/action-types"

 const setProductList=(productList)=>{
    return {
            type:ActionTypes.SET_PRODUCT_LIST,
            payload: productList, 
    };
}
export default setProductList;

