import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardHeader, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { NEWS_PHOTO_BASE_PATH } from './constants/URL';


const CustomCard = (props) => {

  const { data } = props;

  const image_server_path=NEWS_PHOTO_BASE_PATH+data.image_url;

  const navigate = useNavigate();

  const handleCardClick = () => {
    // Handle the click event here
    console.log('Card clicked', data.id);
     navigate('/news');
  };

  let autorAndDate=data.author +"  "+data.postdate; 

  return (

    <Card onClick={handleCardClick} sx={{ display: 'flex', flexDirection: 'row',justifyContent:'start' }} >
      <CardMedia
        component="img"
        sx={{ width: 60, height: 60 }}
        image={image_server_path}
        alt="Image"
      />

      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        titleTypographyProps={{ variant: 'body2', width:'100%' }}
        title={data.heading}
        subheaderTypographyProps={{ variant: 'body2' }}
        subheader= {autorAndDate} />
    </Card>
    // </Box>
  );
};

export default CustomCard;
