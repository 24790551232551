import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import { APP_NAME, FACEBOOK_PAGE } from './constants/URL';


const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#03a9f4", padding: '10px', textAlign: 'center' }}>
      <h4 style={{ color: 'white' }}>Connect with Us</h4>
      <div>
        <a href={FACEBOOK_PAGE} target="_blank" rel="noopener noreferrer">
        <FacebookIcon style={{ fontSize: '2em', margin: '0 10px', color: 'white' }} />
        </a>
      </div>
      <p style={{ color: 'white' }}> Contact Number: +91 96754 77486 </p>
      <p style={{ color: 'white' }}>&copy; 2024 {APP_NAME} . All rights reserved.</p>
    </footer>
  );
};

export default Footer;
