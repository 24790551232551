import React, { useEffect } from 'react';
import ProductCard from './ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_PRODUCT_READ } from '../common/constants/URL';
import setProductList from '../../redux/actions/productAction';
import { Box } from '@mui/material';
import { Exposure } from '@mui/icons-material';
import ProductCardNew from './ProduxtCardNew';


 const ProductList = ({ onProductClick }) => {

  const dispach=useDispatch();

  const fetchDataList=async()=>{
    const response=await axios.get(`${BASE_PRODUCT_READ}`)
    .catch((err)=>{console.log("error >>>>",err);})
   
    dispach(setProductList(response.data));
    
}
useEffect(()=>{
fetchDataList();
},[]);

const dataList =useSelector((state)=>state.allProduct.productList);

const handleProductClick = (product) => {
  // Callback to pass selected product data to the parent component
  onProductClick(product);
};
  return (
    <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
  >
    {dataList?.map((item) => (
    <ProductCard key={item.id}  data={item}  onClick={() => handleProductClick(item)}/>
    ))};
  </Box>
  );
};

export default ProductList;
