import { createTheme } from '@mui/material/styles';;

export const theme = createTheme({
  palette: {
    primary: {
      light: '#03a9f4',
      main: '#2196f3',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    text:{
      main:'#ffffff'
    }
  },
});

