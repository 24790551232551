import { ActionTypes } from "../contants/action-types"

 const setExpertList=(expertList)=>{
    return {
            type:ActionTypes.SET_EXPERT_LIST,
            payload: expertList, 
    };
}
export default setExpertList;

