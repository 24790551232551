import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function SearchBox() {

  const data =useSelector((state)=>state.allCategory.categoryList.categories);
    const navigate = useNavigate();


    const handleItemClick = (event, value) => {
      // Handle the click event on the selected item
      navigate(`/services/${value.name}`);
      console.log('Selected item:', value);
    };
  


  return (
    <Autocomplete sx={{background:'white',borderRadius:'4px',height:'50px'}}
      id="country-select-demo"
      //  sx={{ width: '60%' }}
      fullWidth={true}
      options={data}
      autoHighlight
      getOptionLabel={(option) => option.name}
      onChange={handleItemClick}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`./categoryIcons/${option.name}.png 2x`}
            src={`./categoryIcons/${option.name}.png`}
            alt=""
          />
          {/* {option.name} ({option.code}) +{option.phone}  //we can give here more parameter by simply using as above */} 
           {option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a category"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

