import { combineReducers } from "redux";
import newsReducer from "./newsReducer";
import expertReducer from "./expertReducer";
import categoryReducer from "./categoryReducer";
import bannerReducer from "./bannerReducer";
import videoReducer from "./videoReducer";
import productReducer from "./productReducer";

const reducers=combineReducers({
    allNews:newsReducer,
    allExpert:expertReducer,
    allCategory:categoryReducer,
    allBanner:bannerReducer,
    allVideo:videoReducer,
    allProduct:productReducer

});

export default reducers;