import { ActionTypes } from "../contants/action-types"

 export const setVideoList=(videoList)=>{
    return {
            type:ActionTypes.SET_VIDEO_LIST,
            payload: videoList, 
    };

}

export const setSelectedVideo = (video) => ({
        type: ActionTypes.SET_SELECTED_VIDEO,
        payload: video,
      });

