import React from 'react';
import Card from '@mui/material/Card';
import { Avatar, Box, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const CategoryCard = (props) => {
   const { data } = props;
   const navigate = useNavigate();

  const handleCardClick = () => {
    // Handle the click event here
    console.log('Card clicked', data.id,data.name );
      let fieldName=data.name;
      if(fieldName==='VIEW ALL'){
        navigate(`/services`);  
      }else{
      navigate(`/services/${fieldName}`);
      }
    //}

  };


  return (

    <Card onClick={handleCardClick}
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center',
          width:'100px',
          maxWidth:'100px',
        boxShadow: '2px',
        margin:'10px',
        cursor: 'pointer', // Set default cursor to pointer
        transition: 'cursor 0.3s ease', // Add transition for smooth effect
        '&:hover': {
          cursor: 'pointer', // Change cursor on hover
        }

      }}
     
    >

      {/* <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}> */}
        <Avatar src={`${process.env.PUBLIC_URL}/categoryIcons/${data.name}.png`} sx={{ width: '3rem', height: '3rem' }} />
      {/* </CardContent> */}
      <Typography variant="Body2" style={{ fontSize: 'smaller' }}>{data.name}</Typography>
    </Card>
    

  );
}

export default CategoryCard;
