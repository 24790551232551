import { ActionTypes } from "../contants/action-types"


// reducers/productReducer.js
const initialState = {
  "productList":[
      {
        "id": "11",
        "urls": "[\"url16\",\"url112\"]",
        "name": "Product 3",
        "price": "120.00",
        "discounted_price": "95.00",
        "discount_percentage": "20",
        "seller": "Seller 3",
        "date": "2023-11-25 17:22:11"
        }
      ]
};

  const productReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_PRODUCT_LIST:
        return {
          ...state,
          productList: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default productReducer;


  
  
  