import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CategoryCard from '../common/CategoryCard'
import { Box } from '@mui/material';
import SearchBox from '../common/SearchBox';
import { useSelector } from 'react-redux';


const sortData = (data) => {
  const sortedData = {};
  data.forEach((item) => {
    const startingChar = item.name.charAt(0).toUpperCase();
    if (!sortedData[startingChar]) {
      sortedData[startingChar] = [];
    }
    sortedData[startingChar].push(item);
  });
  return sortedData;
};

const FullCategories = () => {

  const data =useSelector((state)=>state.allCategory.categoryList.categories);
  const sortedData = sortData(data);

  return (
   <Box sx={{ display:'flex', flexDirection:'column ', alignItems:'center', margin: 2 }}>
    <SearchBox  />
    <Grid container spacing={2}>
      {Object.entries(sortedData).map(([char, items]) => (
        <Grid item key={char} xs={12}>
          <Card>
            <CardContent sx={{display:'flex', flexWrap:'wrap',direction:'column'}}>
              <Typography variant="h6">{char}</Typography>
              {items.filter(item=>item.id!==15).map((item) => (
                <CategoryCard key={item.id} data={item}/>
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default FullCategories;
