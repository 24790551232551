import React, { useState } from 'react';
import {   styled } from '@mui/system';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';



 const CategorySelect = ({ onValueChange }) => {
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onValueChange(event.target.value); 
  };

  

  const MySelectFormControl = styled(FormControl)({
    margin: '8px',
    minWidth: '220px',

    
  });

  const data =useSelector((state)=>state.allCategory.categoryList.categories);
  console.log("selected value",selectedValue);

  
  

  return (
    <MySelectFormControl>
      <InputLabel id="demo-simple-select-label">Select Option</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        onChange={handleChange}
      >
        <MenuItem value="">None</MenuItem>
        {data.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </MySelectFormControl>
  );
};

export default CategorySelect;
