import { ActionTypes } from "../contants/action-types"


// reducers/bannerReducer.js
const initialState = {
  "bannerList":{
    "addver": [
      {
      "id": "1",
      "name": "Friday ",
      "photo": "1.jpeg"
      }
      ]
  }
};

  const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_BANNER_LIST:
        return {
          ...state,
          bannerList: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default bannerReducer;


  
  
  