import React, { useEffect } from 'react';
import VideoCard from './VideoCard';
import { BASE_VIDEO_READ } from '../common/constants/URL';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoList } from '../../redux/actions/videoAction';

const VideoList = ( { origin ,onVideoClick }) => {
  const dispatch = useDispatch();
      
  const dataList = useSelector((state) => state.allVideo.videoList);

  useEffect(() => {
    const fetchDataList = async () => {
      try {
        const response = await axios.get(`${BASE_VIDEO_READ}`);
        dispatch(setVideoList(response.data));
      } catch (error) {
        console.error('Error fetching video list:', error);
      }
    };

    if (dataList.video.length === 0) {
      fetchDataList();
    }
  }, [dataList, dispatch]);

let renderList=dataList.video;

 console.log(" origin is " +origin);
      if(origin==='HOME'){
         console.log("video data list is "+dataList.video);
          renderList = dataList.video?.length > 0
         ? dataList.video.slice(0, Math.min(3, dataList.video.length))
         : dataList.video || null;
          }
   
  const handleVideoClick = (selectedVideo) => {
       console.log("clicked "+selectedVideo.id);

    if (onVideoClick) {
      onVideoClick(selectedVideo);
    }
  };

    


  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      {renderList?.map((item) => (
        <VideoCard key={item.id} data={item} onClick={() => handleVideoClick(item)} />
      ))}
    </div>
  );
};

export default VideoList;
