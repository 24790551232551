
export const BASE_URL="https://bisauli.com/";
export const BASE_API_FOLDER="bisauli";
export const APP_NAME="APNA BISAULI";
export const FACEBOOK_PAGE="https://www.facebook.com/MyBisauli/";



export const BASE_URL_V1=BASE_URL+BASE_API_FOLDER+"/api/";
export const BASE_URL_V2=BASE_URL+BASE_API_FOLDER+"/api2";
export const BASE_URL_V4=BASE_URL+BASE_API_FOLDER+"/v4";

export const EXPERT_PHOTO=BASE_URL_V2+"/expertPhoto/";
export const NEWS_PHOTO_UPLOAD =BASE_URL_V2+"/news/imageUpload.php";
export const NEWS_PHOTO_BASE_PATH=BASE_URL_V2+"/images/"


export const BASE_ADDVR_READ= BASE_URL_V1+"/addver/read.php";
export const BASE_BANNER_PHOTO =BASE_URL_V1+"/addPhoto/";
export const CREATE_BANNER=BASE_URL_V1+"/addver/add.php";
export const BANNER_PHOTO_UPLOAD=BASE_URL_V1+"/addver/photoUpload.php";


export const READ_EXPERT =  BASE_URL_V2+"/experts/read.php";
export const READ_NEWS = BASE_URL_V2+"/news/test/read.php";
export const CREATE_NEWS =BASE_URL_V2+"/news/create.php";
export const CREATE_EXPERT_API=BASE_URL_V2+"/experts/add.php";
export const EXPERT_PHOTO_UPLOAD_API=BASE_URL_V2+"/experts/photoUpload.php";



export const BASE_VIDEO_READ= BASE_URL_V4+"/video/read.php";
export const VIDEO_ADD_API = BASE_URL_V4+"/video/add.php";
export const LOGIN_API_ENDPOINT = BASE_URL_V4+"/user/login.php";
export const Register_API_ENDPOINT=BASE_URL_V4+"/user/register.php";

export const SIGNUP_API= BASE_URL_V4+"/user/register.php";
export const BASE_PRODUCT_READ= BASE_URL_V4+"/product/read.php";

export const BASE_PRODUCT_ADD_API= BASE_URL_V4+"/product/add.php";

export const PRODUCT_PHOTO_BASE_PATH=BASE_URL_V4+"/product/uploads/"
