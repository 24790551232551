import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import setExpertList from '../../../redux/actions/expertAction';
import axios from 'axios';
import ProfileCard from './ProfileCard';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { READ_EXPERT } from '../../common/constants/URL';

export const ExpertList = () => {  

  const { dynamicfieldname } = useParams();
  const dispatch=useDispatch();
  
  const fetchDataList=async(param)=>{
        const response=await axios.get(`${READ_EXPERT}?field=${param}`)
        .catch((err)=>{console.log("error >>>>",err);})
        console.log('expert data',response.data);
        dispatch(setExpertList(response.data));
        
   }

  useEffect(()=>{
    fetchDataList(dynamicfieldname);
   },[dynamicfieldname]);

   const sortData = (data) => {
    const sortedData = {};
    data.forEach((item) => {
      const startingChar = item.name.charAt(0).toUpperCase();
      if (!sortedData[startingChar]) {
        sortedData[startingChar] = [];
      }
      sortedData[startingChar].push(item);
    });
    return sortedData;
  };

  const dataList =useSelector((state)=>state.allExpert.expertList);

const sortedData = sortData(dataList.experts);

  return (
  <Grid container spacing={2}>
      {Object.entries(sortedData).map(([char, items]) => (
        <Grid item key={char} xs={12}>
          <Card>
            <CardContent sx={{display:'flex', flexWrap:'wrap',direction:'column'}}>
              <Typography variant="h6">{char}</Typography>
              {items.filter(item=>item.id!==15).map((item) => (
                <ProfileCard key={item.id} data={item}  />
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
