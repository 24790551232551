import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, Input, InputLabel } from '@mui/material';
import { CREATE_BANNER, BANNER_PHOTO_UPLOAD } from '../common/constants/URL';

const AddBanner = () => {
  const [formData, setFormData] = useState({
    name: '',
    photo: '' // New property for storing image URL
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    //const photo_upload_url= BANNER_PHOTO_UPLOAD;

    try {
      // Upload the image first and get the URL
      const imageData = new FormData();
      imageData.append('image', selectedImage);
      const imageResponse = await axios.post(
        BANNER_PHOTO_UPLOAD,
        imageData
      );
  
      console.log('uploading image', imageResponse);
  
      // Update form data with the image URL
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: imageResponse.data.image_url,
      }));
      console.log("formdata",formData);

      // Now, make a POST request with the entire form data
      const response = await axios.post(
        CREATE_BANNER,
        {
          ...formData,
          photo: imageResponse.data.image_url,
        },
        {
          headers: {
            'Content-Type': 'application/json', // or 'multipart/form-data' based on your server requirements
          },
        }
      );

      console.log("formdata",formData);

      // Handle the response as needed (e.g., show a success message)
      console.log('Post API response:', response);
      setSuccess(true);
  
      // Clear the form after successful submission
      setFormData({
        name: '',
        photo: '',
      });
      setSelectedImage(null);
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error posting data:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <Box sx={formContainerStyles}>
      <form onSubmit={handleSubmit}>
        {/* Other input fields */}
        <TextField
          label="Name (100 words limit)"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
          multiline
          rows={4}
        />

       
        <InputLabel htmlFor="image">Upload Image</InputLabel>
        <Input
          type="file"
          id="image"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          fullWidth
        />

        {selectedImage && (
          <div>
            <Typography variant="body1">Selected Image:</Typography>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
              style={selectedImageStyles}
            />
          </div>
        )}

{loading ? (
          <Typography variant="body1">Uploading...</Typography>
        ) : success ? (
          <div>
            <Typography variant="body1">News created successfully!</Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => setSuccess(false)}
            >
              Create Another
            </Button>
          </div>
        ) : (
          <Button type="submit" variant="contained" color="primary" sx={submitButtonStyles}>
            Submit
          </Button>
        )}

      </form>
    </Box>
  );
};

const formContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  margin: 'auto'
};

const selectedImageStyles = {
  maxWidth: '100%',
  maxHeight: '200px',
  marginTop: '10px'
};

const submitButtonStyles = {
  marginTop: '10px'
};

export default AddBanner;
