import React from 'react'
import { FirstRow } from './FirstRow'
import CategoryList from './CategoryList';

import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import ProductHighlightList from '../product/ProductHighlightList';
import VideoHomePage from '../video/VideoHomePage';
import Footer from '../common/Footer';




 const Home = () => {
const theme=useTheme();

  return (
    <div>
    <FirstRow sx={{ p: '10rem', border:1,borderRadius:4 }}/>
    <Divider textAlign="left"   > <Typography variant="h6" component="h2" color={"primary"}>
    Services
    </Typography> </Divider>
    <CategoryList sx={{ border:1,borderColor: 'black', borderRadius: 2 }}/>
    <Divider textAlign="left"><Typography variant="h6" component="h2" color={"primary"}> Buy-Sell </Typography></Divider>
    <ProductHighlightList sx={{ border:1,borderColor: 'black', borderRadius: 2 }}/>
    <Divider textAlign="left"><Typography variant="h6" component="h2" color={"primary"}> Video</Typography></Divider>
    <VideoHomePage/>
    <Footer/>

    </div>

  )
}

export default Home;
