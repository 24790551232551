import React, { useEffect } from 'react';
import VideoList from './VideoList';
import DetailedVideo from './DetailedVideo';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_VIDEO_READ } from '../common/constants/URL';
import { setVideoList} from '../../redux/actions/videoAction';
import { setSelectedVideo } from '../../redux/actions/videoAction'; // Add this import

const VideoMainPage = () => {
  const dispatch = useDispatch();
  const dataList = useSelector((state) => state.allVideo.videoList);
  const selectedVideo = useSelector((state) => state.allVideo.selectedVideo);

  useEffect(() => {
    const fetchDataList = async () => {
      try {
        const response = await axios.get(`${BASE_VIDEO_READ}`);
        dispatch(setVideoList(response.data));
      } catch (error) {
        console.error('Error fetching video list:', error);
      }
    };

    if (dataList.video.length === 0) {
      fetchDataList();
    }
  }, [dataList, dispatch]);

  const handleVideoClick = (selectedVideo) => {
    dispatch(setSelectedVideo(selectedVideo));
  };
  
  const onVideoClick = (selectedVideo) => {
    // Handle click behavior specific to VideoMainPage
    // For example, you may want to update the video details in the main content
    console.log('Clicked on video in VideoMainPage:', selectedVideo);
    dispatch(setSelectedVideo(selectedVideo));
  };

  const selectedItem = selectedVideo || (dataList.video.length > 0 ? dataList.video[0] : null);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {selectedItem && (
        <DetailedVideo key={selectedItem.id} data={selectedItem} style={{ flex: 'none' }} />
      )}
      <div style={{ flex: 1, overflowY: 'auto' }}>
      <VideoList onVideoClick={onVideoClick} />
      </div>
    </div>
  );
};

export default VideoMainPage;
