import React from 'react'
import { ExpertList } from './ExpertList'
import { useParams } from 'react-router-dom';

 const FullExpert = () => {

    const { dynamicfieldname } = useParams();
    
  return (
    <div>
    <ExpertList field={dynamicfieldname}/>
    </div>
  );
};
export default FullExpert;

