import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_PRODUCT_READ } from '../common/constants/URL';
import setProductList from '../../redux/actions/productAction';
import { Box } from '@mui/material';
import { Exposure } from '@mui/icons-material';
import ProductHignlightCard from './ProductHighlightCard';
import ProductCardNew from './ProduxtCardNew';
const MAX_NUMBER_AWAY_FROM_SIZE_OF_CATETEGORY=1000000001;

 const ProductHighlightList = () => {

  const dispach=useDispatch();

  const fetchDataList=async()=>{
    const response=await axios.get(`${BASE_PRODUCT_READ}`)
    .catch((err)=>{console.log("error >>>>",err);})
   
    dispach(setProductList(response.data));
    
}
useEffect(()=>{
fetchDataList();
},[]);

const dataList =useSelector((state)=>state.allProduct.productList);

let length=dataList.length;
 var slicedArray = dataList?.slice(0,  (length > 8)? 8 : length);



  return (
    <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
  >
    {slicedArray?.map((item) => (
    <ProductHignlightCard key={item.id}  data={item} />
    ))};
  </Box>
  );
};

export default ProductHighlightList;
