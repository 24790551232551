import React, { useEffect } from 'react'
import { Box } from '@mui/material';
import VideoList from './VideoList';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { BASE_VIDEO_READ } from '../common/constants/URL';
import { setSelectedVideo, setVideoList} from '../../redux/actions/videoAction';
import { useNavigate } from 'react-router-dom';


const VideoHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

    const dispach=useDispatch();

    const fetchDataList=async()=>{
      const response=await axios.get(`${BASE_VIDEO_READ}`)
      .catch((err)=>{console.log("error >>>>",err);})
     
      dispach(setVideoList(response.data));
      
  }
  useEffect(()=>{
  fetchDataList();
  },[]);
  
  const dataList =useSelector((state)=>state.allVideo.videoList);

      const videoList=dataList?.video;
      const origin ='HOME' ;
   

  const handleVideoClick = (selectedVideo) => {
    dispatch(setSelectedVideo(selectedVideo));
  };

  const onVideoClick = (selectedVideo) => {
    // Handle click behavior specific to VideoMainPage
    // For example, you may want to update the video details in the main content
    console.log('Clicked on video in VideoMainPage:', selectedVideo.id);
    dispatch(setSelectedVideo(selectedVideo));
    navigate('/video'); 
  };
  
  return (
    <Box>
        <VideoList origin={origin} onVideoClick={onVideoClick} />
    </Box>
  )
}

export default VideoHomePage;
