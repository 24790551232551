import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player/youtube';

const DetailedVideo = (props) => {
const {data} =props;

  return (
    <Paper  style={{ maxWidth: 800 }}>
      <ReactPlayer
        url={data.video_url}
        width="100%"
        height="400px"
        controls={true}
      />
         <Typography variant="h5" gutterBottom>
        {data.heading}
      </Typography>
    </Paper>
  );
};

export default DetailedVideo;
