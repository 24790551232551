import { ActionTypes } from "../contants/action-types"


// reducers/videoReducer.js
const initialState = {
  "videoList":{
    "video": [
      ]
  }
};

  const videoReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SET_VIDEO_LIST:
        return {
          ...state,
          videoList: action.payload,
        };

        case ActionTypes.SET_SELECTED_VIDEO:
          return {
            ...state,
            selectedVideo: action.payload,
          };
      default:
        return state;
    }
  };
  
  export default videoReducer;


  
  
  