import React from 'react'
import { useSelector } from 'react-redux';
import DetailedNews from './DetailedNews';

  const NewsList = () => {

    const dataList =useSelector((state)=>state.allNews.newsList);



  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, alignItems:'center' }}>
    {dataList.news?.map((news) => (
      <DetailedNews key={news.id} data={news}/>
    ))}
  </div>
);

};
export default NewsList;
