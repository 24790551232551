import React from 'react';
import { Card, CardContent, CardMedia, Typography, Badge } from '@mui/material';
import {PRODUCT_PHOTO_BASE_PATH} from '../common/constants/URL';
import { Margin } from '@mui/icons-material';


const ProductCard = ({ data, onClick }) => {


  const cardStyle = {
    maxWidth: 300,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',  // Center the content horizontally
    margin:"10px"
  };

  const mediaStyle = {
    width: '100px%', 
    height: 0,
    paddingTop: '56.25%', // 16:9 aspect ratio
    objectFit: 'cover',   // Ensure the image covers the entire space
  };

  const badgeStyle = {
    position: 'absolute',
    top: 10,
    right: 10,
    margin:2
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    flexGrow: 1,  // Allow content to grow within the available space
  };

  

   let image_url = '';

   try {
     const urlsArray = JSON.parse(data.urls);
     image_url = PRODUCT_PHOTO_BASE_PATH + urlsArray[0];    
   } catch (error) {
     console.error('Error parsing urls to JSON:', error);
   }

   const handleCardClick = () => {
    // Callback to pass the selected product data to the parent component
    onClick();
  };


  return (
    <Card onClick={handleCardClick} style={cardStyle}>
      <Badge badgeContent={`${data.discount_percentage}% OFF`} color="error" style={badgeStyle}>
      </Badge>
      <CardMedia
      sx={{ height: 80,width:80 }}
      image= {image_url}
      title={data.name}
    />
      <CardContent style={contentStyle}>
        <Typography variant="Body2" component="div">
          {data.name}
        </Typography>
        <Typography variant="Body2" color="textSecondary">
          <span style={{ textDecoration: 'line-through' }}> ₹ {data.price}</span> ₹ {data.discounted_price}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
