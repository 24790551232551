// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './components/home/Home';
import NewsList from './components/news/NewsList';
import FullCategories from './components/services/FullCategories';
import FullExpert from './components/services/experts/FullExpert';
import VideoList from './components/video/VideoList';
import Dashboard from './components/admin/dashboard/Dashboard';
import Login from './components/user/Login';
import Signup from './components/user/Signup';
import ResponsiveAppBar from './components/common/ResponsiveAppBar';
import ProductList from './components/product/ProductList';
import ProductDetails from './components/product/ProductDetails';
import { AuthProvider } from './components/auth/AuthContext';
import VideoMainPage from './components/video/VideoMainPage';

function App() {
  return (
    <Box>
      <AuthProvider>
        <BrowserRouter>
          <ResponsiveAppBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/services" element={<FullCategories />} />
            <Route path="/services/:dynamicfieldname" element={<FullExpert />} />
            <Route path="/video" element={<VideoMainPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/product" element={<ProductList />} />
            <Route path="/product/details" element={<ProductDetails />} />
            
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Box>
  );
}

export default App;
