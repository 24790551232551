import React from 'react';
import Button from '@mui/material/Button';
import CallIcon from '@mui/icons-material/Call';

const PhoneCallButton = (props) => {
  const {data}=props;
  const handlePhoneCall = () => {
    const phoneNumber = data; // Replace with the desired phone number
    const telUrl = `tel:${phoneNumber}`;

    window.open(telUrl, '_blank');
  };

  return (
    <Button variant="contained" color="primary" onClick={handlePhoneCall} startIcon={<CallIcon/>}>
      Call Now
    </Button>
  );
};

export default PhoneCallButton;
