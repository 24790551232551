import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = (props) => {
  const {data}=props;
  const countryCode = '+91'; // Country code for India

  const handleWhatsAppOpen = () => {
    const phoneNumber = `${countryCode}${data}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    window.open(whatsappUrl, '_blank');
  };

  return (
    <Button variant="contained" color="primary" onClick={handleWhatsAppOpen} startIcon={<WhatsAppIcon/>}>
      WhatsApp
    </Button>
  );
};

export default WhatsAppButton;
