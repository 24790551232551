import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button, Card, CardMedia, CardContent, useMediaQuery, ButtonGroup } from '@mui/material';
import { PRODUCT_PHOTO_BASE_PATH } from '../common/constants/URL';
import WhatsAppButton from '../common/WhatsAppButton';
import PhoneCallButton from '../common/PhoneCallButton';
import { red } from '@mui/material/colors';

const DetailedProduct = ({ productData }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);
  const [serverImages, setServerImages] = useState(null);
  const DEFAULT_PLACEHOLDER_IMAGE_URL = './img/placeholder.png';

  useEffect(() => {
    // Set the first image as the default image
    if (productData && productData.urls) {
      let urlsArray = JSON.parse(productData.urls);
      let fullUrlsArray = urlsArray.map(image => `${PRODUCT_PHOTO_BASE_PATH}${image}`);
      setServerImages(fullUrlsArray);
      setDefaultImage(fullUrlsArray[0]);
      setSelectedImage(fullUrlsArray[0]);
    }
  }, [productData]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // Use mui's useMediaQuery to check if the screen width is less than or equal to 600px
  const isMobile = useMediaQuery('(max-width:600px)');

  if (!productData) {
    return null; // You can render a loading indicator or placeholder here
  }
  const buttonStyle = {
    width: '100%', // Set the width to 100% for equal width
  };

  return (
    <Container>
 <Grid container spacing={3}>
  {/* Larger Image Section */}
  <Grid item xs={12} md={12}>
    <Card>
      <CardMedia
        component="img"
        alt="Selected Image"
        height="400"
        image={selectedImage || defaultImage}
        style={{ width: '100%', objectFit: 'contain' }}
      />
    </Card>
  </Grid>

  {/* Product Details Section */}
  <Grid item xs={12} md={12}>
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {productData.name}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {productData.details}
        </Typography>
        <Typography variant="h6" color="red" gutterBottom bgcolor={red}>
        Deal: {productData.discount_percentage}% OFF
        </Typography>
    
        <Typography variant="h6" color="textSecondary" gutterBottom>
        ₹ {productData.discounted_price} 
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
       <span style={{ textDecoration: 'line-through' }}>  M.R.P.: ₹{productData.price}</span> 
        </Typography>
        <Typography variant="body1" paragraph>
          Seller: {productData.seller}
        </Typography>
        {/* Add more product details as needed */}
        <ButtonGroup  size="medium" color="primary" aria-label="outlined primary button group" >
        <WhatsAppButton style={buttonStyle} data={productData?.phone} />
        <PhoneCallButton style={buttonStyle} data={productData?.phone} />
      </ButtonGroup>
      </CardContent>
    </Card>
  </Grid>

  {/* Thumbnail Images Section */}
  <Grid item xs={12}>
    <Grid container spacing={1}>
      {serverImages?.map((image, index) => (
        <Grid key={index} item xs={2} md={12}>
          <Card
            onClick={() => handleImageClick(image)}
            style={{ cursor: 'pointer', border: selectedImage === image ? '2px solid #1976D2' : 'none' }}
            sx={{ width: '60px', objectFit: 'cover' }}
          >
            <CardMedia component="img" alt={`${productData.name} ${index + 1}`} height="60" width="60" image={image} 
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  </Grid>
</Grid>

    </Container>
  );
};

export default DetailedProduct;
