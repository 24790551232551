import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Box, CircularProgress } from '@mui/material';
import { VIDEO_ADD_API } from '../common/constants/URL';

const AddVideo = () => {
  const [formData, setFormData] = useState({
    heading: '',
    video_url: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // Now, make a POST request with the entire form data
      const response = await axios.post(
        VIDEO_ADD_API,
        formData,
        {
          headers: {
            'Content-Type': 'application/json', // or 'multipart/form-data' based on your server requirements
          },
        }
      );

      console.log("formdata", formData);

      // Handle the response as needed (e.g., show a success message)
      console.log('Post API response:', response);
      setSuccess(true);

      // Clear the form after successful submission
      setFormData({
        heading: '',
        video_url: '',
      });

    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error('Error posting data:', error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <Box sx={formContainerStyles}>
      {loading && <CircularProgress />}
      <form onSubmit={handleSubmit}>
        {/* Other input fields */}
        <TextField
          label="Heading "
          id="heading"
          name="heading"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />
        <TextField
          label="You tube URL"
          id="video_url"
          name="video_url"
          value={formData.name}
          onChange={handleInputChange}
          variant="outlined"
          margin="normal"
          fullWidth
          required
        
        />


        {loading ? (
          <Typography variant="body1">Uploading...</Typography>
        ) : success ? (
          <div>
            <Typography variant="body1">Video added  successfully!</Typography>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => setSuccess(false)}
            >
              Create Another
            </Button>
          </div>
        ) : (
          <Button type="submit" variant="contained" color="primary" sx={submitButtonStyles}>
            Submit
          </Button>
        )}

      </form>
    </Box>
  );
};

const formContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  margin: 'auto'
};


const submitButtonStyles = {
  marginTop: '10px'
};

export default AddVideo;
