import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player/youtube';

const VideoCard = ({ data, onClick, isSelected }) => {
  const handleCardClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick(data);
    }
  };

  const preventDefaultClick = (event) => {
    event.preventDefault();
  };

  return (
    <Paper
      elevation={3}
      style={{ position: 'relative', maxWidth: 340, margin: '4px', padding: 4, cursor: 'pointer' }}
      onClick={handleCardClick}
    >
      <Typography variant="h5" gutterBottom>
        {data.heading}
      </Typography>
      <ReactPlayer
        url={data.video_url}
        width="338px"
        height="190px"
        controls={true}
        playing={isSelected}
      />
      {/* Transparent overlay to prevent clicks */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1, // Set a higher z-index than the ReactPlayer
        }}
        onClick={preventDefaultClick}
      ></div>
    </Paper>
  );
};

export default VideoCard;
