import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { Avatar, Box, ButtonGroup, CardActions, CardContent, Chip, Typography, Button } from '@mui/material';
import { EXPERT_PHOTO } from '../../common/constants/URL';
import WhatsAppButton from '../../common/WhatsAppButton';
import PhoneCallButton from '../../common/PhoneCallButton';

const ProfileCard = (props) => {
  const { data } = props;
  const [showNumbers, setShowNumbers] = useState(false);

  let photo_url = EXPERT_PHOTO + data.photo;

  console.log("EXPERT_PHOTO", photo_url);

  const buttonStyle = {
    width: '100%', // Set the width to 100% for equal width
  };

  const handleButtonClick = () => {
    setShowNumbers(!showNumbers);
  };

  return (
    <Card
      sx={{
        width: 320,
        maxWidth: '100%',
        boxShadow: '8px',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', alignContent: 'center' }}>
        <Avatar src={photo_url} sx={{ width: '8rem', height: '8rem' }} />
        <Chip
          size="small"
          color="primary"
          sx={{
            mt: '-1px',
            mb: '8px',
            border: '3px solid',
            borderColor: (theme) => theme.palette.background.default,
          }}
          label="PRO"
        />
        <Typography variant="h6">{data?.name ?? 'Guest'}</Typography>
        <Typography variant="Body1">{`"${data?.note ?? 'Good Knowledge'}"`}</Typography>
        <Typography variant="body2" sx={{ maxWidth: '24ch' }}>
          {data?.field ?? 'IT'}
        </Typography>
        
        <Typography variant="body2" sx={{ maxWidth: '24ch' }}>
          {data?.address ?? ''}
        </Typography>
        <Box display={'flex'} alignContent={'center'} sx={{ display: 'flex', gap: '8px', mt: '16px', '& > button': { borderRadius: '2rem' } }}>
          <Button variant="outlined" onClick={handleButtonClick}>
            Show Phone Number
          </Button>
        </Box>
        {showNumbers && (
          <>
            <Typography variant="body2" sx={{ maxWidth: '24ch' }}>
              {data?.phone1 ?? 'none'}
            </Typography>
            <Typography variant="body2" sx={{ maxWidth: '24ch' }}>
              {data?.phone2 ?? 'none'}
            </Typography>
          </>
        )}
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'background.level1' }}>
        <ButtonGroup size="medium" color="primary" aria-label="outlined primary button group">
          <WhatsAppButton style={buttonStyle} data={data?.phone1} />
          <PhoneCallButton style={buttonStyle} data={data?.phone1} />
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default ProfileCard;
