import React from 'react';
import  { Box }  from '@mui/material';
import CategoryCard from '../common/CategoryCard'; // Assuming you've created the CustomCard component
import { useSelector } from 'react-redux';
const MAX_NUMBER_AWAY_FROM_SIZE_OF_CATETEGORY=101;

const CategoryList = () => {
 

  const dataList =useSelector((state)=>state.allCategory.categoryList);

  
  var slicedArray = dataList.categories?.slice(0, 9);
  // slicedArray.add

  const newObject = { id: MAX_NUMBER_AWAY_FROM_SIZE_OF_CATETEGORY, name: "VIEW ALL" }; // replace with your new object
  slicedArray = [...slicedArray, newObject];


  return (
  
    <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="space-between"
  >
      {slicedArray.map((item,key) => (
        <CategoryCard key={item.id} data={item}/>
      ))}

    </Box>
  );
};

export default CategoryList;
