import { Box, Divider } from '@mui/material';
import React from 'react';
import { Rightbar } from './Rightbar';
import CarouselComponent from './CarouselComponent';

const containerStyle = {
    display: 'flex',
     flexWrap:"wrap",
    
    
  };
  
  const leftColumnStyle = {
    flex: '50%',
    //backgroundColor: 'lightblue',
    padding: '10px',
  };
  
  const rightColumnStyle = {
    flex: '40%',
    backgroundColor: 'lightblack',
    padding: '10px',
  };

export const FirstRow = () => {
    // return (
    //     <Box display="flex"  flexDirection="row" flexWrap="wrap" spacing={0} sx={{ p: 2 }}>
    //             <Feed />
    //             <Rightbar />
    //     </Box>
    // )

    return (
        <Box style={containerStyle}>
          <div style={leftColumnStyle}>
          <CarouselComponent />
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={rightColumnStyle}>
          <Rightbar />
          </div>
        </Box>
      );

}
