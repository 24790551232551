import React, { useState } from 'react';
import { Container, Grid, Divider } from '@mui/material';
import DetailedProduct from './DetailedProduct'; // Import your detailed product component
import ProductList from './ProductList'; // Import your product list component
import { useLocation } from 'react-router-dom';

const ProductDetails = () => {
const { state } = useLocation();
const productData = state && state.productData;


const [selectedProduct, setSelectedProduct] = useState(productData);




  const handleProductClick = (productId) => {
    // Update the selected product ID when a product is clicked
    setSelectedProduct(productId);
     // Scroll to the top of the screen
  window.scrollTo({
    top: 0,
    behavior: 'auto', // You can change this to 'auto' if you prefer an instant scroll
  });
  };

  return (
    <Container>
      <Grid container spacing={3}>
        {/* Detailed Product Component */}
        <Grid item xs={12} md={6}>
          <DetailedProduct productData={selectedProduct} />
        </Grid>
        {/* Divider */}
        <Grid item xs={12} md={12}>
          <Divider />
        </Grid>
        {/* Product List Component */}
        <Grid item xs={12} md={6}>
          <ProductList onProductClick={handleProductClick} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDetails;
